import { User } from '../../graphql';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { Avatar, Card, Col, Row } from 'antd';
import { colors, getInitials, getRoleText, getUserLocation } from '../../utils';
import { Text } from '../Text';
import { useMatch, useNavigate } from 'react-router-dom';

export default function UserItem({ user }: { user: User }) {
  const navigate = useNavigate();
  const isMembersPage = useMatch('/members');
  const { sm } = useBreakpoint();

  return (
    <Col xs={24} xl={isMembersPage ? 12 : 24}>
      <Card
        className={'card-md-padding clickable user-card'}
        onClick={() => {
          const url = `/user/${user.id}`;
          window.open(url, '_blank');
        }}
        
      >
        <Row
          gutter={16}
          justify={'space-between'}
          style={{ flexWrap: 'nowrap' }}
        >
          <Col>
            <Row gutter={16} align={'middle'} style={{ flexWrap: 'nowrap' }}>
              <Col>
                <Avatar size={sm ? 72 : 54} src={user.profile?.picture}>
                  {getInitials(user?.firstName, user?.lastName)}
                </Avatar>
              </Col>
              <Col style={{ display: 'grid' }}>
                <Text variant={sm ? 'lgStrong' : 'baseStrong'}>
                  {user?.firstName + ' ' + user?.lastName}
                </Text>
                <Text
                  ellipsis
                  variant={'smNormal'}
                  color={'black4'}
                  style={{ marginTop: 2 }}
                >
                  {getRoleText(user.role)}
                </Text>
                {sm && (
                  <div
                    style={{
                      padding: '2px 10px',
                      background: colors.blue1,
                      borderRadius: 4,
                      marginTop: 6,
                      width: 'fit-content',
                    }}
                  >
                    <Text variant={'smMedium'} color={'blue6'}>
                      {getUserLocation(user.profile?.location)}
                    </Text>
                  </div>
                )}
              </Col>
            </Row>
          </Col>
          {!!user.distance && (
            <Col>
              <div
                style={{
                  padding: '2px 10px',
                  background: colors.blue6,
                  borderRadius: 4,
                  marginTop: 6,
                  width: 'fit-content',
                }}
              >
                <Text
                  ellipsis
                  variant={'smMedium'}
                  color={'white'}
                  style={{ textWrap: 'nowrap' }}
                >
                  {`${Number(user.distance).toFixed()} km away`}
                </Text>
              </div>
            </Col>
          )}
        </Row>
      </Card>
    </Col>
  );
}
